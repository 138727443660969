@import 'src/styles/config';
.listItem {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  aspect-ratio: 1.7;
  border-radius: $sizing12px;
  border: $sizing2px solid #f89300;
  padding: $sizing20px $sizing20px $sizing10px $sizing20px;
  font-size: $sizing14px;
  cursor: pointer;
  background: url('#{$imgPath}/free-spins/free-spin-card-bgi.png') no-repeat;
  background-size: cover;
  background-clip: border-box;
}

.content {
  display: flex;
  gap: $sizing14px;

  .tileImgBox {
    width: 32.79%;

    .tileImg {
      position: static;
      width: 100%;
      height: auto;
    }
  }

  .details {
    display: flex;
    flex-direction: column;
    justify-content: center;

    .header {
      font-style: italic;
      line-height: 1;
      font-weight: $font-weight-900;
      font-size: $sizing24px;
      text-transform: uppercase;

      .highlight {
        font-size: $sizing40px;
        color: $colorGc;
      }
    }

    .roundsRemain {
      font-size: $sizing18px;
      font-weight: $font-weight-400;
      margin-top: $sizing10px;

      span {
        color: $colorGc;
      }
    }
  }
}

.footer {
  display: flex;
  justify-content: space-between;

  .dateSection {
    $fontSizeDefault: px-to-rem(13);
    $fontSizeClamp: clamp(#{$fontSizeDefault}, 2vw, #{$sizing14px});

    display: grid;
    grid-template-columns: auto 1fr;
    font-size: $fontSizeDefault;

    @supports (font-size: $fontSizeClamp) {
      font-size: $fontSizeClamp;
    }
  }

  .button {
    pointer-events: none;
    max-width: px-to-rem(101);
    padding-top: 0;
    padding-bottom: 0;
    margin: 0;
    min-height: $sizing26px;
  }
}
