@import 'src/styles/config';
.root {
  width: 100%;
  height: calc(100% - $mobileMenuHeight + 2px);

  @media screen and (min-width: $sm) {
    display: none;
  }

  @media (orientation: landscape) {
    padding-right: env(safe-area-inset-right);
  }
}

.drawer {
  position: relative;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  background-color: $base10;
  padding: 0 12px 32px;
}

.drawerBlurred {
  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background-image: url('#{$imgPath}/blur-horizontal.png');
    background-size: cover;
    background-position: top center;
    padding-bottom: 70%;
  }
}

.avatar {
  width: 30px;
  height: 30px;
  margin-left: 0.5rem;
}

.logoLink {
  min-height: 64px;
  display: flex;
  align-items: flex-start;
  width: 100%;
  padding: 18px 0;

  @media (max-width: $sm) {
    justify-content: center;
  }
}

.drawerLogo {
  transform: none;
  left: 0;
  top: 6px;
}

.topContent {
  padding-top: env(safe-area-inset-top);
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.button {
  margin-top: 0.5rem;
}

.navigationButton {
  width: 32px;
  height: 32px;
  color: $primary-white;
  max-width: unset;
  min-width: unset;
}

.drawerModal {
  height: calc(100% - $mobileMenuHeight + 2px);
  z-index: 1299;
}

.closeImage {
  width: 16px;
  height: 16px;
}

.hidden {
  visibility: hidden;
  height: 10px;
}

// shifts visible area for side menu drawer, to make top mobile header visible when drawer are opened

.topShift {
  height: calc(100% - $mobileMenuHeight * 2 + 2px);
  top: $mobileMenuHeight;
}
