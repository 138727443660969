@import 'src/styles/config';
.button {
  color: $base100;
  font-size: $sizing14px;
  line-height: 1;
  width: 100%;
  min-height: px-to-rem(46);
  margin: 0 0 $sizing12px;
  padding: $sizing12px;
  justify-content: space-between;

  & > * {
    gap: $sizing10px;
    display: flex;
    align-items: center;
  }
}
