$base0: #000000;
$base5: #0d0d0d;
$base10: #191919;
$base20: #333333;
$base30: #4d4d4d;
$base40: #666666;
$base60: rgb(153 153 153 / 100%);
$base70: #b3b3b3;
$base80: #cccccc;
$base100: #ffffff;
$brand-interface: #0c0c0c;
$brand-accent-primary: #fa114f;
$brand-accent-secondary: #95e759;
$brand-tournament: #188e95;
$brand-tournament-border: #07646a;
$brand-tournament-shadow: #063d40;
$brand-exclusive: #9b43c1;
$brand-exclusive-border: #7a03ae;
$button-disabled-txt-primary: #7d0828;
$rainbowBackground: linear-gradient(
  95.03deg,
  #f9a836 0.02%,
  #ffea43 13.02%,
  #83ea9a 25.02%,
  #0feaf1 38.02%,
  #3492f0 50.02%,
  #6362e2 63.02%,
  #9150c4 75.02%,
  #c55b7b 88.02%,
  #fa7532 100.02%
);
$grandJackpot: #dcf254;
$majorJackpot: #52ff82;
$minorJackpot: #94cbfb;
$miniJackpot: #ffce1a;
$goldLoyalty: #fde256;
$primaryColor: #fbab00;
$borderColor: rgba(84, 84, 84, 0.3);
