@import 'src/styles/config';
.root {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-width: 205px;
  padding: 24px 16px 0 4px;
}

.firstRow {
  display: flex;
  align-items: center;
  gap: 6px;
}

.winner {
  color: #ffffff;
  font-size: 14px;
  font-weight: 700;
  line-height: 1.15;
}

.amount {
  display: flex;
  align-items: center;
  gap: 4px;
  margin-bottom: 4px;

  img {
    .root.gc & {
      height: 22px;
    }

    .root.sc & {
      height: 19px;
    }
  }

  span {
    font-size: 20px;
    font-weight: 700;
    font-style: italic;
    line-height: 1.15;

    .root.gc & {
      color: #febe1c;
    }

    .root.sc & {
      color: #44fa5b;
    }
  }
}

.spin {
  color: #ffffff;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.15;

  span {
    font-weight: 700;
  }
}
