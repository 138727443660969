@import 'src/styles/config';
$drawerWidth: px-to-rem(428);

.drawer {
  top: 0;
  bottom: 0;
  background: $base0;
  box-shadow: 0 5px 24px 0 black inset;
}

.drawerContent {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: $sizing40px;
  overflow-y: scroll;

  @media screen and (max-width: 414px), screen and (max-width: 1024px) and (orientation: landscape) {
    padding: $sizing20px;
  }

  // Safari doesn't support scrollbar-* properties
  @supports (scrollbar-color: auto) {
    scrollbar-color: $base20 transparent;
    scrollbar-width: auto;
    scroll-behavior: smooth;
  }

  // Scrollbar styles for Safari and Opera
  @supports selector(::-webkit-scrollbar) {
    &::-webkit-scrollbar {
      background: transparent;
      width: 7px;
    }

    &::-webkit-scrollbar-thumb {
      background: $base20;
      border-radius: 4px;
    }
  }
}

.drawerContentNonItems {
  padding: $sizing60px $sizing40px;
}

.drawerDesktop {
  left: $desktopMenuWidth;
  width: $drawerWidth;
}

.drawerMobile {
  left: 0;
  right: 0;
  width: 100%;
}

.drawerFadeIn {
  animation-fill-mode: both;
  animation-timing-function: ease-in-out;
  animation-duration: 300ms;
  animation-name: fade;
}

@keyframes fade {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.drawerFadeOut {
  animation-fill-mode: both;
  animation-timing-function: ease-in-out;
  animation-duration: 200ms;
  animation-name: fadeOut;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.drawerHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: px-to-rem(0, 15);
  min-height: px-to-rem(50);
  background-color: $base0;
  border-bottom: $sizing2px solid rgba(84, 84, 84, 0.3);

  @media screen and (max-width: $xs) and (orientation: portrait) {
    padding-top: env(safe-area-inset-top);
    padding-bottom: $sizing4px;
  }
}

.loading {
  pointer-events: none;

  &::before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: #333;
    opacity: 0.4;
  }
}

.linearProgress {
  height: $sizing2px;
  position: absolute;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
}
