$contrast-text: #fff;
$contrast-text-secondary: #000;
$disabled-color: rgba(0, 0, 0, 0.38);
$divider-color: rgba(0, 0, 0, 0.12);
$error-light: #e57373;
$error-main: #f44336;
$error-dark: #d32f2f;
$login-divider: #121212;
$dialog-background: #fff;
$navbar-background: #fff;
$menu-background: #fff;
$header-background: #fff;
$input-background: #fff;
$shop-widget-background: #fff;
$payment-widget-background: #fff;
$redeem-widget-background: #fff;
$background-page: #f2f3f5;
$background-store: linear-gradient(-135deg, #ffb300 4.17%, #f57c00 96.87%);
$background-store-widget: #fff;
$background-badge: #bdbdbd;
$border-badge: #fefefe;
$primary-main: #0084ce;
$primary-background: #fff;
$primary-sweep: #00ad45;
$secondary-main: #00ad45;
$text-primary: rgba(0, 0, 0, 0.87);
$text-secondary: rgba(0, 0, 0, 0.54);
$sweep-gradient: linear-gradient(-135deg, #1bee02 0.56%, #00ad45 100%);
$sweep-color: #00ad45;
$sweep-text: #fff;
$sweep-lighter: #1bee02;
$sweep-box-shadow: 0px 2px 4px -1px rgba(0, 173, 69, 0.2) 0px 4px 5px 0px rgba(0, 173, 69, 0.14) 0px 1px 10px 0px rgba(0, 173, 69, 0.12);
$gold-gradient: linear-gradient(-135deg, #ffb300 4.17%, #f57c00 96.87%);
$gold-color: #f57c00;
$gold-text: #fff;
$gold-lighter: #ffb300;
$gold-box-shadow: 0px 2px 4px -1px rgba(245, 124, 0, 0.2), 0px 4px 5px 0px rgba(245, 124, 0, 0.14),
  0px 1px 10px 0px rgba(245, 124, 0, 0.12);
$default-gradient: linear-gradient(-135deg, #fe139c 0%, #c71cb8 100%);
$default-color: #c71cb8;
$default-text: #fff;
$default-lighter: #fe139c;
$default-box-shadow: 0px 2px 4px -1px rgba(199, 28, 184, 0.2), 0px 4px 5px 0px rgba(199, 28, 184, 0.14),
  0px 1px 10px 0px rgba(199, 28, 184, 0.12);

// contrast colors
$contrast-text-secondary-87: rgba(0, 0, 0, 0.87);
$contrast-text-secondary-80: rgba(0, 0, 0, 0.8);
$contrast-text-secondary-60: rgba(0, 0, 0, 0.6);
$contrast-text-secondary-54: rgba(0, 0, 0, 0.54);
$contrast-text-secondary-50: rgba(0, 0, 0, 0.5);
$contrast-text-secondary-38: rgba(0, 0, 0, 0.38);
$contrast-text-secondary-23: rgba(0, 0, 0, 0.23);
$contrast-text-secondary-12: rgba(0, 0, 0, 0.12);
$contrast-text-secondary-4: rgba(0, 0, 0, 0.04);
$text-primary-80: rgba(0, 0, 0, 0.8);
$text-primary-70: rgba(0, 0, 0, 0.7);
$default-lighter-30: rgba($default-lighter, 0.3);
$default-color-8: rgba($default-color, 0.08);
$sweep-color-8: rgba($sweep-color, 0.08);
$gold-color-8: rgba($gold-color, 0.08);
$spinner-color: #ffc107;
