@import 'src/styles/config';
.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.fullscreen {
  height: calc(100vh - $headerHeight);

  @media screen and (max-width: $sm) {
    height: 100vh;
  }
}
