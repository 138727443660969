@import 'src/styles/config';
.skeleton {
  display: flex;
  width: 100%;
  position: relative;
  overflow: hidden;
  background-color: $base10;
  border-radius: $border-radius-default;

  &::after {
    position: absolute;
    inset: 0;
    transform: translateX(-100%);
    background-image: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0) 0,
      rgba(255, 255, 255, 0.05) 15%,
      rgba(255, 255, 255, 0.1) 30%,
      rgba(255, 255, 255, 0.15) 45%,
      rgba(255, 255, 255, 0.1) 60%,
      rgba(255, 255, 255, 0.05) 75%,
      rgba(255, 255, 255, 0) 100%
    );
    animation: shimmer 3s infinite;
    content: '';
  }

  @keyframes shimmer {
    100% {
      transform: translateX(100%);
    }
  }
}
