@import 'src/styles/config';
.overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  will-change: opacity;
  backdrop-filter: blur(4px);
}

.grow {
  animation-name: growAnimation;
  animation-fill-mode: both;
  animation-timing-function: ease-in-out;
  animation-duration: 200ms;
}

.hideGrow {
  animation-name: hideAnimationGrow;
  animation-fill-mode: both;
  animation-timing-function: ease-in-out;
  animation-duration: 200ms;
}

@keyframes growAnimation {
  from {
    opacity: 0;
    transform: scale(0.3);
  }

  to {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes hideAnimationGrow {
  from {
    opacity: 1;
    transform: scale(1);
  }

  to {
    opacity: 0;
    transform: scale(0.3);
  }
}

// Slide animation

.slide {
  animation-fill-mode: both;
  animation-timing-function: ease-in-out;
  animation-duration: 300ms;
}

.slideUp {
  animation-name: slideAnimationUp;
}

.slideBottom {
  animation-name: slideAnimationUp;
  transform-origin: top;
}

.slideLeft {
  animation-name: slideAnimationLeft;
}

.slideRight {
  animation-name: slideAnimationRight;
}

.hideSlide {
  animation-fill-mode: both;
  animation-timing-function: ease-in-out;
  animation-duration: 200ms;
}

.hideSlideUp {
  animation-name: hideAnimationSlideUp;
}

.hideSlideBottom {
  animation-name: hideAnimationSlideUp;
}

.hideSlideLeft {
  animation-name: hideAnimationSlideLeft;
}

.hideSlideRight {
  animation-name: hideAnimationSlideRight;
}

@keyframes slideAnimationUp {
  from {
    opacity: 0;
    transform: translateY(100%);
  }

  to {
    opacity: 1;
    transform: translateY(0%);
  }
}

@keyframes slideAnimationLeft {
  from {
    transform: translateX(100%);
  }

  to {
    transform: translateX(0%);
  }
}

@keyframes slideAnimationRight {
  from {
    transform: translateX(-100%);
  }

  to {
    transform: translateX(0%);
  }
}

@keyframes hideAnimationSlideUp {
  from {
    opacity: 1;
    transform: translateY(0%);
  }

  to {
    opacity: 0;
    transform: translateY(100%);
  }
}

@keyframes hideAnimationSlideLeft {
  from {
    transform: translateX(0%);
  }

  to {
    transform: translateX(100%);
  }
}

@keyframes hideAnimationSlideRight {
  from {
    transform: translateX(0%);
  }

  to {
    transform: translateX(100%);
  }
}
