@import 'src/styles/config';
.progressBarItem {
  border-top: 2px solid #25662C;
  padding-top: 16px;
  margin-top: 16px;
}

.titleHolder {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
}

.title {
  color: $base100;
  font-size: 14px;
  font-weight: $font-weight-500;
}

.percent {
  color: $base90;
  font-size: 12px;
  font-weight: $font-weight-400;
}

.levels {
}
