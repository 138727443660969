@import 'src/styles/config';
.root {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: $sizing3Rem;
  margin: $spacing1d5Rem 0 $spacing0d75Rem;
  position: relative;
  z-index: 1;

  @media screen and (max-width: $sm) {
    min-height: $sizing2Rem;
    margin: $spacing1Rem 0 $spacing0d5Rem;
  }
}

.arrowImg {
  width: 100%;
  display: block;
}

.icon {
  transform: rotate(180deg);
  margin-left: 4px;
  height: $sizing0d75Rem;
  width: $sizing0d75Rem;
}

.title {
  padding: 0 16px;
  margin: 16px 0;
}

.titleWithLink {
  margin: 0;
  display: flex;
  align-items: center;
  font-size: $fontXL;
  font-weight: $font-weight-500;
  line-height: 1.4rem;
  color: $textColorPrimary;

  @media screen and (min-width: $lg) {
    font-size: $fontLG;
  }
}

.titleWithTag {
  display: flex;
  flex: 1;
  justify-content: space-between;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.4rem;

  &.largeTitle {
    font-weight: 700;
  }
}

.buttonArrow {
  width: 24px;
  height: 24px;
  border-radius: 0;
  margin-right: 8px;
  margin-left: auto;
  padding: 0;

  &:hover {
    background: none;
  }

  &:focus-visible {
    opacity: 0.85;
  }

  &:nth-last-of-type(1) {
    margin-right: 0;
    margin-left: 0;
  }

  &.squaredBtn {
    border-radius: $border-radius-default;
    background: $base0;
    box-shadow: $pillBoxShadow;
  }
}

.buttonArrowIconRight {
  transform: rotate(180deg);
}

.link {
  display: block;
  font-size: $fontSM;
  font-weight: $font-weight-500;
  line-height: 100%;
  margin: 0 0 0 16px;
  align-items: center;
  cursor: pointer;
  user-select: none;
  text-decoration: none;
  padding: 8px 16px;

  @include button-secondary-colors;

  &.squaredBtn {
    border-radius: $border-radius-default;
    background-color: $base0;
    color: $base70;
    padding: 6px 12px;
    text-decoration: none;
    white-space: nowrap;
    box-shadow: $pillBoxShadow;

    img {
      display: none;
    }
  }
}

.linkArrow {
  margin-bottom: 2px;
  font-size: 1rem;
}

.tag {
  display: flex;
  align-items: center;
  color: $base10;
  background: $primaryColor;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  padding: 4px 24px;
  cursor: pointer;
  border-radius: 8px;

  @media screen and (max-width: $sm) {
    font-size: 10px;
  }
}

.arrowIcon {
  margin-left: 4px;
  width: 16px;
  height: 16px;

  @media screen and (max-width: $sm) {
    width: 14px;
    height: 14px;
    margin-left: 2px;
  }
}

.promotionLink {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 6px;
}
