@import 'src/styles/config';
.link {
  user-select: none;
  text-decoration: none;
  padding: 16px;
  width: 100%;
  font-size: 14px;
  font-style: normal;
  font-weight: $font-weight-500;
  line-height: 19.6px;
  display: flex;
  align-items: center;
  cursor: pointer;
  color: #fff;
  border: none;
  border-bottom: 2px solid $borderColor;
  background-color: transparent;

  & > svg {
    margin-right: 0.5rem;
  }

  &:hover {
    .linkTitle {
      color: $primaryColor;
    }
  }
}

.linkTitle {
  color: #fff;

  &:hover {
    color: $primaryColor;
  }
}

.icon {
  margin-right: $spacing0d75Rem;
}

.iconRegular {
  width: 20px;
  height: 20px;
}

.iconLarge {
  width: 24px;
  height: 24px;
}

.GC {
  .linkTitle {
    color: $primaryColor;
  }
}

.SC {
  .linkTitle {
    color: $primaryColor;
  }
}

.USD {
}

.activeLink {
}

.alterColor {
}
