@import 'src/styles/config';
.closeBtn {
  border-radius: 0;
  width: 40px;
  height: 40px;
  min-width: 0;

  &:hover,
  &:focus {
    filter: brightness(0) saturate(100%) invert(78%) sepia(89%) saturate(5251%) hue-rotate(1deg) brightness(99%)
      contrast(99%);
  }
}
