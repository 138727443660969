@import 'src/styles/config';
.wrapper {
  display: flex;
  align-items: center;

  .backBtn {
    margin: 0 4px 0 0;
    padding: 0;
  }

  @media (min-width: $md) {
    margin-right: 64px;
  }

  .logoHolder {
    position: relative;
    width: auto;
    height: 46px;

    @media (max-width: $sm) {
      height: 32px;
    }
  }
}
