@import 'src/styles/config';
.badge {
  position: relative;

  &::after {
    content: '';
    display: block;
    position: absolute;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #ff0000;
    top: -4px;
    right: -4px;
  }
}
