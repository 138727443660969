@import 'src/styles/config';
.badge {
  min-width: $sizing20px;
  aspect-ratio: 1;
  border-radius: 100%;
  background-color: #ff0000;
  display: inline-flex;
  justify-content: center;
  align-items: center;

  &.opticalCenter {
    padding: 0 $sizing2px $sizing2px 0;
  }
}

.loader {
  height: $sizing18px;
  width: $sizing18px;
}
