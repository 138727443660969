@import 'src/styles/config';
.description {
  margin: $spacing0d5Rem 0 $spacing1Rem;
  color: $base100;

  @include paragraph-pSM-general;

  @media screen and (min-width: $md) {
    @include paragraph-pMD-general;
  }

  a {
    color: $base100;
    text-decoration: underline;
    font-weight: $font-weight-500;
  }
}
