@import 'src/styles/config';
.language-switcher {
  margin-top: 16px;
  display: flex;
  width: 100%;
  align-items: flex-start;
}

.language-link {
  display: block;
  margin-right: 16px;
  cursor: pointer;

  &:last-child {
    margin-right: 0;
  }
}

.language-flag-icon {
  display: block;
}
