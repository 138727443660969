@import 'src/styles/config';
.root {
  width: $sizing2d5Rem;
  height: $sizing2d5Rem;
  cursor: pointer;
  border-radius: 20%;
  overflow: hidden;
}

.image {
  width: 100%;
  height: 100%;
  display: block;
}
