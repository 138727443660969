@import 'src/styles/config';
.root {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 32px 0 0;
  justify-content: flex-end;
}

.logoutBtn {
  background: $black26;
  color: $primary-white;
  border-radius: 12px;
  padding: 10px 12px;
  margin-top: 16px;
}
