@import 'src/styles/config';
.root {
  margin-bottom: 16px;

  h6 {
    color: $base100;
    margin: 3px 0 16px;
  }
}

.description {
  color: $base100;
  font-weight: $fontWeightPrimaryBold;
  font-size: $fontMD;
  line-height: $lineMD;
}

.withShadow {
  -webkit-text-stroke: 0.3px $base0;
  text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
}
