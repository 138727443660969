@import 'src/styles/config';
$animation-duration: 1s;

.root {
  position: fixed;
  bottom: 115px;
  right: -16px;
  z-index: 9;
  width: 128px;
  height: 128px;
  animation: slide $animation-duration;
  transform: translateY(0);
  transition: transform $animation-duration;

  @media screen and (max-width: $sm) {
    bottom: unset;
    top: calc(12px + env(safe-area-inset-top));
    background: transparent;
    width: 40px;
    height: 40px;
    right: 12px;
  }

  .button {
    width: auto;
    min-width: auto;
    height: auto;
    background: unset;
    padding: unset;
    outline: unset;
    border: unset;
    cursor: pointer;

    .present {
      width: 100%;
      height: 100%;
    }
  }

  .amount {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    border: 2px solid #ff0000;
    background: #ff0000;
    width: $sizing1d25Rem;
    height: $sizing1d25Rem;
    right: 36px;
    top: 32px;
    font-weight: $fontWeightPrimaryBold;
    font-size: 11px;
    line-height: $lineMD;
    text-align: center;
    color: $base100;
    border-radius: 50%;

    @media screen and (max-width: $sm) {
      right: 0;
      top: 0;
    }
  }

  @keyframes slide {
    from {
      transform: translateX(100%);
    }

    to {
      transform: translateX(0);
    }
  }
}
