@import 'src/styles/config';
.root {
  position: absolute;
  z-index: -1;
}

.gcWin {
  top: -40px;
  height: 72px;
}

.scWin {
  top: -25px;
  height: 50px;
}

.gcJackpotWin {
  top: -82px;
  height: 133px;
}

.scJackpotWin {
  top: -80px;
  height: 133px;
}
