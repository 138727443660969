@import 'src/styles/config';
.root {
  position: fixed;
  width: $desktopMenuWidth;
  height: 100%;
  left: 0;
  z-index: 3;
}

.logo {
  display: flex;
  align-items: center;
  padding: 0;
  height: $headerHeight;
  justify-content: center;
  position: relative;
  flex-shrink: 0;
  margin: 0;
}

$skeleton-heights: (127px, 50px, 80px, 100px);

.skeleton {
  * + * {
    margin-top: 12px;
  }

  @for $i from 1 through length($skeleton-heights) {
    & :nth-child(#{$i}) {
      height: nth($skeleton-heights, $i);
    }
  }
}
