@import 'src/styles/config';
$defaultCardWidth: #{px-to-rem(394)};
$minCardWidth: #{px-to-rem(300)};
$smallScreenHeight: #{px-to-rem(389)};

.list {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.listDesktop {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  gap: $sizing20px;
}

.listMobile {
  $maxValue: max($defaultCardWidth, 1fr);

  display: grid;
  grid-template-columns: repeat(auto-fill, minmax($minCardWidth, 1fr));
  gap: $sizing10px;

  @media (orientation: landscape) and (max-height: $smallScreenHeight) {
    margin: 0 auto;
    grid-template-columns: repeat(auto-fill, minmax($minCardWidth, $defaultCardWidth));
  }
}
