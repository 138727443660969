@import 'src/styles/config';
.favoriteButton {
  padding: 0;
  width: 40px;
  height: 40px;
  filter: $favoriteButtonFilter;

  &:hover {
    background-color: transparent;
  }

  img {
    flex-shrink: 0;
    width: 24px;
    height: 24px;
  }
}

.hasCaption {
  width: auto;
  height: auto;

  img {
    margin-right: 8px;
  }
}

.caption {
  font-size: 14px;
  color: $base100;
  white-space: nowrap;

  @media (max-width: $sm) {
    display: none;
  }
}
