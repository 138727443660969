@import 'src/styles/config';
.root {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0 0 9px 9px;
}

.gc {
  background: linear-gradient(338.77deg, #febe1c 10.7%, #fbda01 94.67%);
}

.sc {
  background: linear-gradient(17.48deg, #1ee05c 15.79%, #4bff54 90.8%);
}

.text {
  font-size: 16px;
  font-weight: 700;
  font-style: italic;
  line-height: 1.15;
  letter-spacing: 1px;
  color: #ffffff;
  background: #000000;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-stroke: 3px transparent;
  padding: 0 6px;
}
