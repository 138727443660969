@import 'src/styles/config';
.root {
  display: inline-block;
  vertical-align: top;
  object-fit: contain;
  position: relative;
  z-index: 1;
  align-self: flex-end;
  height: 131px;
  transform: rotateZ(-8deg);
  bottom: 8px;
  margin-left: -12px;
  border-bottom-left-radius: 16px;
}
