@import 'src/styles/config';
.root {
  width: 100%;
}

.scrollContainer {
  position: relative;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;
  z-index: 1;

  @media screen and (max-width: $sm) {
    padding-left: 12px;
    padding-right: 12px;
    margin-left: -12px;
    margin-right: -12px;
  }

  &::-webkit-scrollbar {
    display: none;
  }
}

.scrollWrap {
  position: relative;
  overflow: visible;

  &:hover > :global(svg) {
    opacity: 1;
  }

  &:hover :global(.game-icon-prev) {
    left: 0;
  }

  &:hover :global(.game-icon-next) {
    right: 0;
  }

  &:global(.hidePrevlArrow > .prev-banner-arrow) {
    visibility: hidden;
  }

  &:global(.hideNextlArrow > .next-banner-arrow) {
    visibility: hidden;
  }

  .backdropRainbow {
    top: 0;
    left: 0;
    width: 100%;
    height: calc(100% - 20px);
    margin-top: 20px;

    @media (max-width: $sm) {
      height: calc(100% - 30px);
      margin-top: 30px;
    }
  }
}

:global(.has-sticky) {
  .scrollWrap .backdropRainbow {
    top: 0;
    left: 0;
  }
}

.tournamentName {
  @media screen and (max-width: $sm) {
    margin: 0 1rem 0.4rem;
  }

  margin-bottom: 0.4rem;
}

.scrollButton {
  position: absolute;
  cursor: pointer;
  top: 35%;
  font-size: 3rem;
  z-index: 1;
  opacity: 0;
  transition: all 0.3s;
  background: rgb(1 55 90 / 70%);
  color: white;

  @media screen and (max-width: $sm) {
    display: none;
  }
}

.scrollButtonPrev {
  border-radius: 0 50% 50% 0;
  left: -4rem;
}

.scrollButtonNext {
  right: -4rem;
  border-radius: 50% 0 0 50%;
}

.tail {
  min-width: 1px;
}

@keyframes buttonArrowSmoothAppearing {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.buttonArrow {
  position: absolute;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  animation-name: buttonArrowSmoothAppearing;
  animation-duration: 0.3s;
  width: 18px;
  height: 32px;
  padding: 0;

  &.squaredBtn {
    border-radius: $border-radius-default;
  }

  .arrowImg {
    width: 100%;
    display: block;
  }
}

.buttonArrowIconLeft {
  transform: rotate(135deg);
  font-size: 1rem;
}

.prevButtonArrow {
  left: -8px;
  top: calc(50% - 16px);
}

.buttonArrowIconRight {
  transform: rotate(-45deg);
  font-size: 1rem;
}

.nextButtonArrow {
  right: -8px;
  top: calc(50% - 16px);
}
