@import 'src/styles/config';
.buttonsContainer {
  display: flex;
  align-items: center;
  text-decoration: none;
  font-size: 14px;

  & > * + * {
    margin-left: 8px;
  }
}

.loginLink {
  height: $sizing2d5Rem;
  border-radius: $border-radius-default;
  padding-left: 16px;
  padding-right: 16px;
  margin-top: 0;
  margin-bottom: 0;

  @include button-secondary-colors;
}

.signupLink {
  padding-left: 16px;
  padding-right: 16px;
  height: $sizing2d5Rem;
  margin-top: 0;
  margin-bottom: 0;

  &:not(&:global(.mt-button-contained)) {
    color: $primary-white;
  }
}
