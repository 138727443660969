@import 'src/styles/config';
.root {
  display: flex;
  align-items: center;
  position: relative;
  left: 16px;
}

.shopLink {
  margin-right: 16px;
}

.currencyText {
  align-items: center;
  display: flex;
  font-size: $fontMD;
  font-weight: $font-weight-500;
  justify-content: flex-end;
  line-height: 0.75rem;
  position: relative;
  white-space: nowrap;

  @media screen and (max-width: $xs) {
    font-size: $fontSM;
  }
}

.icon {
  width: 16px;
  height: 16px;
  margin-right: 4px;

  @media screen and (max-width: $xs) {
    width: 12px;
    height: 12px;
  }
}

.info {
  align-items: center;
  background-color: transparent;
  border: 0;
  cursor: pointer;
  display: flex;
}

.infoIcon {
  font-size: 14px;
  color: $base100;
  width: 15px;
  height: 15px;

  @media screen and (max-width: $xs) {
    font-size: $fontSM;
  }
}

.currency {
  margin-right: 0.5rem;
  // mobile
  @media screen and (max-width: $xs) {
    margin-right: 1rem;
  }
}
