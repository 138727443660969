@import 'src/styles/config';
.title {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  margin-top: 12px;
  padding: 0;
  margin-bottom: 16px;
  font-size: 1.25rem;
  font-weight: $font-weight-400;
  line-height: 1.5;

  h2 {
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
    margin: 0;
  }
}
