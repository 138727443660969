@import 'src/styles/config';
$loyaltyBg: #3da749;

.root {
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
  background-color: $loyaltyBg;
  border: 2px solid $base80;
  border-radius: $border-radius-default;
  margin-bottom: 12px;
  padding: 16px;
  position: relative;
  z-index: 1;
  box-shadow: $block-shadow;
}

.titleContainer {
  @include text-highlight-thLG-general;

  display: flex;
  justify-content: space-between;

  .titlePart {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .title {
    font-size: 16px;
    font-weight: 500;
    color: $base100;
  }

  .endDate {
    color: $base100;
    opacity: 0.7;
    font-size: 12px;
    line-height: 1.4;
  }

  .iconPart {
    background: #25662c;
    border-radius: 6px;
    padding: 8px 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 70px;
    height: 70px;

    img {
      height: 26px;
      display: block;
      margin-bottom: 4px;
    }

    .caption {
      font-size: 12px;
      color: $base90;
      line-height: 1;
      text-align: center;
    }
  }

  .percent {
    @include text-highlight-thMD-general;
  }

  .icon {
    margin-left: $sizing0d5Rem;
    display: block;

    & > * {
      width: $sizing1Rem;
      height: $sizing1Rem;
      display: block;
    }
  }
}

.levels {
  @include text-highlight-thMD-general;

  margin-top: $sizing0d25Rem;
  color: $base80;
  display: flex;
  justify-content: space-between;
}

.accordionBody {
  transition: max-height 300ms ease-out 0ms;
  position: relative;
  text-align: right;
}

.accordionBtn {
  margin-top: auto;
  color: $base80;
  font-size: 12px;
  line-height: 1;
  cursor: pointer;
  padding: 8px 16px;

  @include button-secondary-colors;
}

.hasArrow {
  display: inline-flex;
  align-items: center;

  &::after {
    content: '';
    display: block;
    width: 16px;
    height: 16px;
    background-image: url('#{$imgPath}/menu/arrow_down.svg');
    background-size: 100%;
    background-position: center;
    background-repeat: no-repeat;
    transition: transform 0.3s;
  }
}

.arrowUp {
  &::after {
    transform: rotate(-180deg);
  }
}

.moreLink {
  display: inline-flex;
  margin-top: 16px;
  font-size: 14px;
  font-weight: $font-weight-500;
  line-height: 1.4;
  text-decoration-line: underline;
  color: $base100;
}

.skeleton {
  height: 241px;
  border-radius: 8px;
  margin-bottom: 12px;
}
