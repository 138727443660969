@import 'src/styles/config';
.root {
  padding: 24px 0;
  position: relative;
  border-top: 2px solid $borderColor;
  background: linear-gradient(266deg, #212121 2%, #000 99.87%);

  @media screen and (max-width: $sm) {
    &::before {
      width: 100%;
    }
  }
}

.footerContainer {
  position: relative;
  z-index: 1;

  &.centered {
    max-width: 85%;
    margin: auto;
  }
}

.footerMenuHolder {
  display: flex;
  flex-wrap: wrap;
  gap: 8px 32px;

  @media screen and (max-width: $sm) {
    display: block;
  }
}

.twoColumnHolder {
  flex-wrap: nowrap;

  .fmBlock {
    width: 50%;

    @media screen and (max-width: $sm) {
      width: 100%;
    }
  }
}

.buttonsList {
  .fmItem {
    margin: 0 16px 16px 0;

    a {
      border-radius: $border-radius-default;
      padding: 8px 16px;
      text-decoration: none;
      font-size: 12px;
      line-height: 1;
      display: block;

      @include button-secondary-colors;
    }
  }
}

.footerMenuHolder + .footerMenuHolder {
  margin: 12px 0 0;
  padding-bottom: 8px;
}

.text {
  font-style: normal;
  font-weight: $font-weight-400;
  font-size: 12px;
  line-height: 140%;
  color: $base100;

  span {
    @media screen and (max-width: 479px) {
      display: block;
    }
  }
}

.telWrapper {
  @media screen and (max-width: 500px) {
    margin-bottom: 8px;
  }
}

.text + .text {
  margin-top: 8px;
}

.telBlock {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 12px;

  .text + .text {
    margin-top: 0;
  }

  .text {
    margin-right: 24px;
    white-space: nowrap;
  }

  @media screen and (max-width: 500px) {
    flex-direction: column;
  }
}

.telOneLine {
  display: flex;
  flex-wrap: wrap;

  .text {
    margin-right: 8px;
    white-space: normal;
  }
}

.textWhite {
  color: $base100;
}

.fmBlock + .fmBlock {
  @media screen and (max-width: $sm) {
    margin-top: 12px;
  }
}

.divider {
  background: $borderColor;
  height: 2px;
  width: 100%;
  margin: 16px 0;
}

.textBlock {
  a {
    font-weight: $font-weight-500;
    text-decoration-line: underline;
    color: $base100;
  }
}

.footerTitle {
  font-weight: $font-weight-500;
  font-size: 14px;
  line-height: 20px;
  color: $base100;
  margin: 0 0 12px;

  a {
    color: $base100;
  }
}

.footerMenu {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  @media screen and (max-width: $sm) {
    flex-wrap: wrap;
  }
}

.fmItem {
  margin: 0 24px 24px 0;

  @media screen and (max-width: $sm) {
    margin: 0 12px 12px 0;
  }

  a {
    font-weight: $font-weight-500;
    font-size: 12px;
    line-height: 20px;
    text-decoration-line: underline;
    color: $base60;
    white-space: nowrap;
  }
}

.footerLogoHolder {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: $sizing1d5Rem;

  .logo {
    height: $sizing3d5Rem;
    width: auto;
    margin-bottom: $spacing0d5Rem;
    position: static;
    transform: none;
  }
}

.leftAligned {
  align-items: flex-start;
}
