@import 'src/styles/config';
html {
  .ticker {
    --latest-wins-ticker-gc-bg: linear-gradient(338.77deg, #febe1c 10.7%, #fbda01 94.67%);
    --latest-wins-ticker-gc-color: #191921;
    --latest-wins-ticker-sc-bg: linear-gradient(17.48deg, #1ee05c 15.79%, #4bff54 90.8%);
    --latest-wins-ticker-sc-color: #191921;

    .tag {
      --latest-wins-ticker-tag-top: -13px;
      --latest-wins-ticker-tag-width: 60px;
      --latest-wins-ticker-tag-height: 20px;
      --latest-wins-ticker-tag-mobile-top: -8px;
      --latest-wins-ticker-tag-mobile-width: 36px;
      --latest-wins-ticker-tag-mobile-height: 12px;
    }
  }
}
