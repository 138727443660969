@import 'src/styles/config';
$barHeight: $mobileMenuHeight;
$barContentHeight: 130px;

.bottomTabsBar {
  width: 100%;
}

.accordionHeader {
  height: $barHeight;
  background-color: hsla(0deg, 0%, 5%, 0.8);
  display: flex;
  align-items: center;
  padding: 0 12px;

  .close {
    margin-left: auto;
    color: $base100;
    background: none;
    padding: 0;
  }

  &.scratchfulStyle {
    background-color: $brand-interface;

    button.active {
      background-color: $base10;
    }
  }

  .button {
    border-radius: 8px;
    height: 40px;
    background-color: #333;
    margin-right: 8px;
    padding: 16px;
    border: none;

    span {
      font-size: 14px;
      color: #ccc;

      span {
        margin-right: 8px;
      }
    }

    img:first-child {
      margin-right: 8px;
      opacity: 0.7;
    }

    &.active {
      background-color: #4d4d4d;

      img {
        opacity: 1;
      }

      span {
        color: #ffffff;
      }
    }

    &.scratcfulFilter {
      & img {
        filter: brightness(0) saturate(100%);
      }

      &.active {
        img {
          filter: brightness(0) saturate(100%);
        }
      }
    }
  }

  .toggleDown {
    transform: scaleY(1);
    transition: all 0.2s linear;
  }

  .toggleUp {
    transform: scaleY(-1);
    transition: all 0.2s linear;
  }
}

.accordionBody {
  overflow: hidden;
  max-height: 0;
  transition: max-height 250ms ease-out 0ms;
  display: flex;
  align-items: center;
  height: $barContentHeight;
  padding: 0 10px;
  background-color: #191919;
}

.expanded {
  max-height: $barContentHeight;
  transition: max-height 250ms ease-in 0ms;
}

.slotsRowContainer {
  display: flex;
  align-items: center;
  height: $barContentHeight;
  width: 100%;

  .slotsRow {
    width: 100%;
  }
}

.loader {
  height: $barContentHeight;
}

.emptyItem {
  width: 80px;
  height: 106px;
  background-color: #4d4d4d;
  border-radius: 8px;
  font-size: 10px;
  line-height: 14px;
  display: grid;
  place-items: center;
  text-align: center;
  padding: 0 1px;
  color: rgba(255, 255, 255, 0.87);
}

.desktop {
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 3;
  margin-left: $desktopMenuWidth;
  width: calc(100% - $desktopMenuWidth);
  box-shadow: $pillBoxShadow;
  overflow: hidden;
  display: none;

  @media screen and (min-width: $sm) {
    display: block;
  }

  .accordionHeader {
    clip-path: none;
    filter: none;
  }

  .accordionBody {
    margin: 0;

    .slotsRow {
      & :global {
        .style-small {
          grid-auto-columns: 80px;
          grid-gap: 8px;
        }
      }
    }
  }
}
