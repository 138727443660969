@import 'src/styles/config';
.root {
  padding: 16px;
  margin: -16px;
}

.white {
  color: #fff;
}

.black {
  color: $primary-white;
}
