@import 'src/styles/config';
html {
  .gc {
    --providers-jackpots-ticker-gc-color: #ffb300;
  }

  .sc {
    --providers-jackpots-ticker-sc-color: #00ff41;
  }
}
