@import 'src/styles/config';
.menuDesktop {
  background: $base10;
  position: fixed;
  width: $desktopMenuWidth;
  height: 100%;
  left: 0;
  padding: 0 12px 24px;
  display: none;
  flex-direction: column;
  z-index: 3;
  transition: left 0.3s;
  overflow: auto;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  @media screen and (min-width: $sm) {
    display: flex;
  }

  &::after {
    // content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: -1;
    background-image: url('#{$imgPath}/blur-vertical.png');
    background-size: cover;
    background-position: center;
    height: 492px;
    opacity: 0.8;
  }
}

.main {
  flex: 1;
  padding-top: $headerHeight;
  position: relative;
  min-height: 100vh;

  &.fullWidthContainer {
    :global(.main-container.limited):not([class*='footerContainer']) {
      max-width: unset;
      padding: 0;
    }

    [class*='pageContent'] {
      max-width: 1160px;
      padding: 0 24px;

      @media screen and (max-width: $sm) {
        padding: 0 12px;
      }
    }
  }

  // desktop
  @media screen and (min-width: $sm) {
    margin-left: $desktopMenuWidth;
  }
  // mobile
  @media screen and (max-width: $sm) {
    padding-top: calc($mobileMenuHeight + env(safe-area-inset-top) + 10px);
    padding-bottom: $mobileMenuHeight;
    // fix sizes for ios with notch
    // Browsers which partially support CSS Environment variables (iOS 11.0-11.2).
    @supports (padding-top: constant(safe-area-inset-bottom)) {
      --safe-area-inset-bottom: constant(safe-area-inset-bottom);

      padding-bottom: calc($mobileMenuHeight + var(--safe-area-inset-bottom));
    }
    // Browsers which fully support CSS Environment variables (iOS 11.2+).
    @supports (padding-top: env(safe-area-inset-bottom)) {
      --safe-area-inset-bottom: env(safe-area-inset-bottom);

      padding-bottom: calc($mobileMenuHeight + var(--safe-area-inset-bottom));
    }

    &.hasPatternBg {
      &::before {
        width: 100%;
      }
    }
  }
}

.footer {
  margin-left: $desktopMenuWidth;

  &.hiddenMenu {
    margin-left: 0;
  }

  &.extraPadding {
    padding-bottom: 50px;
  }

  @media screen and (max-width: $sm) {
    padding-bottom: $mobileMenuHeight;
    margin-left: auto;

    &.extraPadding {
      padding-bottom: $mobileMenuHeight * 2;
    }
  }

  :global(.main-container) {
    padding: 0 24px 40px;

    @media (max-width: 933px) {
      padding: 0 12px 40px;
    }
  }
}

.fullContent {
  margin-left: 0;
}

.disabled {
  pointer-events: none;
  filter: blur(4px) saturate(0);
}
