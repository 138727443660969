@import 'src/styles/config';
.root {
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: $headerHeight;
  justify-content: space-between;
  z-index: 3;
  background-color: #000;
  border-bottom: 2px solid $borderColor;
  box-shadow: none;
  padding: 12px 0 12px $desktopMenuWidth;

  @media (max-width: $sm) {
    top: env(safe-area-inset-top);
    padding: 0;
    min-height: $mobileHeaderHeight;
  }

  &.isHeaderWithLogo {
    padding: 0;
  }

  &::after {
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    height: env(safe-area-inset-top);
    width: 100%;
    background-color: $brandBase1;
  }
}

.headerWrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  @media screen and (max-width: $md) {
    justify-content: space-between;
  }
}

.solidAppbar {
  // background: $brandInterfaceModal;
}

.headerMaxWidthContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and (min-width: $xlg) {
    max-width: $maxContentWidth;
    margin: auto;
  }
}

.headerContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.logoLink {
  text-decoration: none;
}

.logo {
  display: flex;
  align-items: center;
  font-weight: $font-weight-700;
  color: $primary-white;

  @media (max-width: $sm) {
    height: 40px;
    width: auto;
  }

  @media (orientation: landscape) {
    margin-left: env(safe-area-inset-left);
  }
}

.getCoin {
  height: 40px;
  margin-top: 0;
  margin-bottom: 0;
}

.buttons {
  width: 100%;
  max-width: 300px;
  display: flex;
  justify-content: flex-end;
}

.avatarLink {
  text-decoration: none;
}

.infoContainer {
  display: flex;
  align-items: center;
  min-width: 280px;
  justify-content: flex-end;

  @media (max-width: $sm) {
    min-width: auto;
  }

  @media (orientation: landscape) {
    margin-right: env(safe-area-inset-right);
  }
}

.avatar {
  margin-right: 4px;
  width: 44px;
  height: 44px;
  cursor: pointer;
}

.store-btn {
  color: $primary-white;
  margin-left: 8px;
  width: 94px;
}

.logoWithClose {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.disabled {
  pointer-events: none;
  filter: blur(4px) saturate(0);
}

.logoHolder {
  margin: 0;
}

.logoutBtn {
  margin-left: auto;
}
