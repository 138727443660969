@import 'src/styles/config';
.root {
  padding: 19px 24px;
  // border: 1px solid $base80;
  background: $base10;
  // box-shadow: 2px 2px 0px 0px $base80;

  @media screen and (max-width: $sm) {
    width: 95vw;
    padding: 19px 42px 19px 24px;
  }
}

.snackbarContent {
  position: relative;
  display: initial;
  max-width: 460px;
  box-shadow: none;

  &:global(.mt-snackbar-content) {
    min-width: 420px;
    bottom: 56px;
  }

  @media screen and (max-width: $sm) {
    &:global(.mt-snackbar-content) {
      bottom: 71px;
    }
  }
}

.close {
  position: absolute;
  color: $base100;
  cursor: pointer;
  right: 6px;
  top: 7px;

  span > svg {
    &:global(.mt-svg-icon-root) {
      width: 0.75em;
      height: 0.75em;
    }
  }
}

.message {
  color: $base100;
  font-weight: $font-weight-500;

  &:global(.text-bigger) {
    @media screen and (max-width: $sm) {
      font-size: 14px;
    }
  }
}

.additionalMargin {
  &:global(.mt-snackbar-content) {
    bottom: 65px;

    @media screen and (max-width: $sm) {
      bottom: 120px;
    }
  }
}
