@import 'src/styles/config';
.linearProgress {
  position: relative;
  overflow: hidden;
  display: block;
  height: $sizing4px;
  z-index: 0;
  background-color: $base20;

  .bar {
    position: absolute;
    left: 0;
    bottom: 0;
    top: 0;
    transition: transform 0.2s linear 0s;
    transform-origin: left center;
    background-color: $brandAccentPrimary;
    width: auto;

    &One {
      animation: 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395) 0s infinite normal none running animation-bar-one;
    }

    &Two {
      animation: 2.1s cubic-bezier(0.165, 0.84, 0.44, 1) 1.15s infinite normal none running animation-bar-two;
    }

    @keyframes animation-bar-one {
      0% {
        left: -35%;
        right: 100%;
      }

      60% {
        left: 100%;
        right: -90%;
      }

      100% {
        left: 100%;
        right: -90%;
      }
    }

    @keyframes animation-bar-two {
      0% {
        left: -200%;
        right: 100%;
      }

      60% {
        left: 107%;
        right: -8%;
      }

      100% {
        left: 107%;
        right: -8%;
      }
    }
  }
}
