@import 'src/styles/config';
.divider {
  margin: 1rem;
  margin-bottom: 0;
  border: 0;
  height: 1px;
  background: #ffffff;
  mix-blend-mode: normal;
  opacity: 0.12;
}

.disabled {
  pointer-events: none;
  filter: blur(5px) saturate(0);
}

.links {
  overflow: auto;
  border-radius: 0;
  margin: 0 -12px;
  position: relative;
  z-index: 1;
}

.halfWidth.halfWidth {
  width: 50%;
}

.shopLink {
  background: $primaryColor;
  width: 50%;
  height: $sizing2d5Rem;
  margin: 0;
}

.redeemLink {
  width: 50%;
  height: $sizing2d5Rem;
  margin: 0;
}

.linkContained {
  @include button-secondary-colors;
}

.linkOutlined {
  @include button-ghost-colors;
}

.allCategories {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 56px;
  font-weight: $font-weight-500;
  font-size: 14px;
  line-height: 16px;
  text-transform: uppercase;
  color: #ffffff;
  cursor: pointer;
}

.balanceBlock {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 0 0 12px;
  padding: 26px 16px;
  font-weight: $font-weight-500;
  font-size: 20px;
  line-height: 24px;
  color: $balanceBlockColor;
  overflow: unset;
  border-radius: 8px;
  background-color: #000;
}

.shopBtns {
  display: flex;
  width: 100%;
  gap: 8px;
  margin-top: 18px;
}

.balance {
  margin: 10px 0;
}

.balanceLabel {
  width: 100%;
}

.text {
  text-decoration: none;
  padding: 12px 16px;
  font-size: 14px;
  font-style: normal;
  font-weight: $font-weight-500;
  line-height: 18px;
  display: flex;
  align-items: center;
  color: $textColorSecondary;
}

.text,
.switcher {
  &:hover {
    background-color: get(contrast-text-secondary-4);
  }
}

.menuAccordion {
  @include text-highlight-thMD-general;

  display: flex;
  justify-content: space-between;
  padding: 16px;
  width: 100%;
  align-items: center;
  cursor: pointer;
  user-select: none;
  color: #fff;
  border: none;
  border-bottom: 2px solid $borderColor;
  background-color: transparent;

  & > svg {
    margin-right: 0.5rem;
  }

  &:hover {
    .linkTitle {
      color: $primaryColor;
    }
  }
}

.menuAccordionIcon {
  margin-right: $spacing0d75Rem;
  width: 20px;
  height: 20px;
}

.menuAccordionActive {
  color: $primaryColor;
}

.menuAccordionTitleBlock {
  display: flex;
  align-items: center;
  justify-content: center;
}

.menuAccordionTitleBlockActive {
  color: $primaryColor;
}

.arrow {
}

.menuAccordionContent {
  overflow: hidden;
  max-height: 0;
  visibility: hidden;
  transition: max-height 300ms ease-out 0ms, visibility 300ms ease-out 0ms;
}

.expanded {
  max-height: 1000px;
  visibility: visible;
  transition: max-height 250ms ease-in 0ms, visibility 250ms ease-in 0ms;
}

.currency {
  img {
    height: 16px;
    width: 16px;

    @media screen and (max-width: $xs) {
      height: 16px;
      width: 16px;
    }
  }

  span {
    font-size: $fontMD;

    @media screen and (max-width: $xs) {
      font-size: $fontMD;
    }
  }
}
