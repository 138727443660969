@import 'src/styles/config';
.root {
  margin-bottom: 12px;
  position: relative;
  z-index: 1;

  &::after {
    content: '';
    display: block;
    width: 106px;
    position: absolute;
    background-image: url('#{$imgPath}/refer-a-friend/sidebar-raf-img-v3.png');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    top: -1px;
    bottom: 0;
    right: 0;
    z-index: -1;
    border-radius: 8px;
  }

  a {
    display: flex;
    align-items: center;
    position: relative;
    height: 56px;
    padding: 8px 106px 8px 8px;
    border-radius: 8px;
    font-style: normal;
    font-weight: $font-weight-400;
    font-size: 14px;
    line-height: 12px;
    color: $textColorSecondary;
    text-decoration: none;

    &::before {
      content: '';
      position: absolute;
      z-index: -1;
      inset: 0;
      background: linear-gradient(266deg, #212121 2%, #000 99.87%);
      border-radius: inherit;
    }
  }

  .textGreen {
    font-weight: $font-weight-500;
    color: $colorSc;
  }

  img {
    vertical-align: middle;
    margin-right: 4px;
  }
}

.compact {
  a {
    padding-top: 5px;
    line-height: 20px;
  }
}
