@import 'src/styles/config';
.countdown {
  display: flex;
  font-size: 14px;
  line-height: 1;
  color: inherit;
  flex-wrap: wrap;
  justify-content: center;

  & > div {
    display: flex;
  }

  .countdownText {
    margin-right: 4px;
    white-space: nowrap;
  }

  .clockIcon {
    width: 15px;
    height: 15px;
    margin-left: 3px;
  }
}

.claimBtn {
  text-transform: capitalize;
}
