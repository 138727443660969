@import 'src/styles/config';
.error {
  background-color: $primaryColor;

  &:hover {
    background-color: $primaryColor;
  }

  &:disabled {
    background-color: $primaryColor;
  }
}

.success {
  background-color: $primaryColor;

  &:hover {
    background-color: $primaryColor;
  }

  &:disabled {
    background-color: $primaryColor;
  }
}

.info {
  background-color: $primaryColor;

  &:hover {
    background-color: $primaryColor;
  }

  &:disabled {
    background-color: $primaryColor;
  }
}

.warning {
  background-color: $primaryColor;

  &:hover {
    background-color: $primaryColor;
  }

  &:disabled {
    background-color: $primaryColor;
  }
}

.variantIcon {
  font-size: 20px;
  margin-right: 0.5rem;
}

.iconVariant {
  opacity: 0.9;
  margin-right: 8px;
}

.root {
  background-color: $base10;

  @media screen and (max-width: $sm) {
    width: 90vw;
  }

  .messageTitle {
    @media screen and (max-width: $sm) {
      font-size: 18px;
    }
  }

  .infoText,
  .contactUs {
    color: $base100;
  }

  .infoText {
    font-size: $fontMD;
  }

  .button {
    font-size: $fontLG;

    @media screen and (max-width: $sm) {
      font-size: $fontMD;
    }

    &:hover {
      opacity: 0.8;
    }
  }

  .close {
    cursor: pointer;
    right: 16px;
    top: 16px;
    padding: 0;
    color: $textColorPrimary;
  }
}

.message {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  max-width: 100%;

  a {
    text-decoration: underline;
  }
}

.messageText {
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
  color: $contrast-text;
}

.messageTextWithButton {
  margin-right: 8px;
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
}

.actionButton {
  color: $primary-black;
  border: 1px solid $primary-black;
  width: 130px;
  margin-left: auto;
  margin-bottom: 0;
}

.modalLikeSnackbar {
  inset: 0;
}

.modalLikeSnackbarContent.modalLikeSnackbarContent {
  position: relative;
  display: initial;
  max-width: 460px;
  box-shadow: none;
}
