@import 'src/styles/config';
// vars

$gap-desktop: 12px;
$gap-mobile: 8px;

// end

// mixins

@function get-item-width($qnt, $gap) {
  @return calc((100% / $qnt) - (($qnt - 1) * $gap / $qnt));
}

// end

// container styles

.tilesGrid {
  display: grid;
  grid-gap: $gap-desktop;
  grid-auto-flow: column;

  @include snap;

  picture img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
  }

  &.socialBannersContainer {
    grid-auto-columns: get-item-width(2, $gap-desktop);
  }

  &.tilesContainer {
    grid-auto-columns: get-item-width(6, $gap-desktop);

    &:global {
      &.lines-2 {
        grid-template-rows: 1fr 1fr;
      }

      &.lines-3 {
        grid-template-rows: 1fr 1fr 1fr;
      }

      &.style-large {
        grid-auto-columns: get-item-width(4, $gap-desktop);
      }

      &.style-small {
        grid-auto-columns: get-item-width(8, $gap-desktop);
      }

      &.style-heading {
        grid-template-rows: 1fr 1fr;
        grid-auto-columns: get-item-width(8, $gap-desktop);

        & > * {
          &:first-child {
            grid-column: 1/3;
            grid-row: 1/3;

            .logo-pragmatic-jackpot {
              width: 75px;
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: $sm) {
    grid-gap: $gap-mobile;

    &.socialBannersContainer {
      grid-auto-columns: 100%;
    }

    &.tilesContainer {
      grid-auto-columns: 112px;

      &:global {
        &.style-large {
          grid-auto-columns: 186px;
        }

        &.style-small {
          grid-auto-columns: 80px;
        }

        &.style-heading {
          grid-auto-columns: 80px;
        }
      }
    }
  }
}

.hsContainer + .hsContainer {
  margin-top: 12px;
}

// end
