@import 'src/styles/config';
$border-radius: 100px;

.progressContainer {
  --progress: 0;

  position: relative;
  background: #25662c;
  height: 6px;
  overflow: hidden;
  width: 100%;
  margin: 0 25px;
  border-radius: 4px;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: var(--progress);
    transition: width 0.2s;
    border-radius: 4px;
    background: linear-gradient(270deg, #ffe561 0.31%, #ffbe29 100%);
  }

  .progress {
    opacity: 0;
  }
}

.progressBarHolder {
  position: relative;
  height: 26px;
  display: flex;
  align-items: center;
  margin-bottom: 4px;
}

.icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  height: 26px;
  display: block;

  &.iconStart {
    left: 0;
  }

  &.iconEnd {
    right: 0;
  }
}

.levels {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 12px;
  font-weight: $font-weight-500;
  line-height: 140%;
  color: $base90;

  & > *:last-child {
    margin-left: auto;
  }
}
