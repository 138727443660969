@import 'src/styles/config';
.promoCount {
  display: inline-flex;
  width: 20px;
  height: 20px;
  justify-content: center;
  align-items: center;
  margin-left: 5px;
  color: $primary-white;
  border: 1px solid #ff0000;
  background: #ff0000;
  border-radius: 50%;
  font-size: 11px;
}
