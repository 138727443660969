@import 'src/styles/config';
$left-padding: 28px;

.root {
  position: relative;
  display: flex;
  align-items: flex-end;
  min-width: max-content;
  height: 190px;
  padding: 0 0 0 $left-padding;
  overflow-y: hidden;
  cursor: pointer;
}

.container {
  display: flex;
  min-width: max-content;
  height: 108px;
  border: 1px solid #ff9000;
  border-radius: 9px;
}

.gc {
  background: #482501;
}

.sc {
  background: #0f1b39;
}

.winBlock {
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: center;
}

.footer {
  position: absolute;
  z-index: 2;
  bottom: 0;
  right: 0;
  left: $left-padding;
}
