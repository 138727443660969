@import 'src/styles/config';
.root {
  position: fixed;
  width: 100%;
  height: $mobileMenuHeight;
  left: 0;
  top: auto;
  bottom: 0;
  z-index: 100;
  // fix sizes for ios with notch
  // Browsers which partially support CSS Environment variables (iOS 11.0-11.2).
  @supports (padding-top: constant(safe-area-inset-bottom)) {
    height: calc($mobileMenuHeight + var(--safe-area-inset-bottom));

    @media (max-width: 345px) {
      min-height: calc($mobileMenuHeight + var(--safe-area-inset-bottom));
      height: auto;
    }
  }

  // Browsers which fully support CSS Environment variables (iOS 11.2+).
  @supports (padding-top: env(safe-area-inset-bottom)) {
    height: calc($mobileMenuHeight + var(--safe-area-inset-bottom));

    @media (max-width: 345px) {
      min-height: calc($mobileMenuHeight + var(--safe-area-inset-bottom));
      height: auto;
    }
  }
}

.disabled {
  pointer-events: none;
  filter: blur(5px) saturate(0);
}

.bottomNavigation {
  background: $brandInterfaceHeaderLvl1;
  height: $mobileMenuHeight;
  padding: $spacing0d5Rem $spacing0d75Rem;
  justify-content: space-between;
  border-top: 2px solid rgba(84, 84, 84, 0.3);
}

.image {
  height: 16px;
  width: 16px;
  filter: $mobileMenuIconFilter;
}

.shopLink {
  height: $sizing2d5Rem;
}

.menuActions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-left: auto;

  & > * + * {
    margin-left: 12px;
  }
}

.cash {
  border-radius: 4px;
  padding-left: 4px;
  padding-right: 16px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: 6px;
  margin-top: 6px;
}

.navigationButton {
  min-width: unset;
  max-width: unset;
  width: 32px;
  height: 32px;
  color: $primary-white;
}

.GC {
  color: get(gold-color);
}

.SC {
  color: $green;
}

.USD {
  color: red;
}

.headerLink {
  margin: auto;
}

.cashShop {
  display: flex;
  align-items: center;
}
