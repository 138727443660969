@import 'src/styles/config';
$min-tile-width-mobile: 112px;
$min-tile-height-mobile: 150px;
$min-small-tile-width-mobile: 80px;
$min-small-tile-height-mobile: 106px;
$min-tile-width-desktop: 120px;
$eight-items-in-grid-coefficient: 1.667;
$shadow-offset: 4px;
// empty space on the image from one of the sides, 16/450
$image-side-offset: 3.56%;
// empty space on the image from the top, 32/450
$image-top-offset: 7.12%;
$ar-tile: 133.333%;

.root {
  cursor: pointer;
  position: relative;

  .imageContainer {
    position: relative;
    // saving space for shadow at the bottom of the image, so it won't be hidden by container's overflow
    padding-top: calc($ar-tile + $shadow-offset);

    &::after {
      content: '';
      display: block;
      background-color: #101d42;
      position: absolute;
      width: calc(100% - $image-side-offset * 2);
      height: calc(100% - $image-top-offset - $shadow-offset);
      left: calc($image-side-offset + $shadow-offset);
      bottom: 0;
      z-index: -1;
    }

    .image {
      box-shadow: none;
      display: block;
      width: 100%;
      // returning original aspect ratio for image after saving space for shadow at the bottom
      height: calc(100% - $shadow-offset);
      position: absolute;
      z-index: 0;
      top: 0;
      left: 0;
      user-select: none;
      -webkit-user-drag: none;
      -webkit-touch-callout: none;
      transition: all 0.3s;
      text-indent: -10000px;
    }

    .icon {
      fill: map-get($gray, 'A200');
      width: 24px;
      height: 24px;
    }
  }

  &.rootVisible {
    opacity: 1;
    transform: scale(1);
  }
}

.favouriteBlock {
  position: absolute;
  top: calc(3px + $image-top-offset);
  right: 0;
  width: 40px;
  height: 24px;

  button {
    width: 100%;
    height: 100%;
  }

  img {
    width: 24px;
    height: 24px;
  }
}
