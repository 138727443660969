@import 'src/styles/config';
.tags {
  position: absolute;
  z-index: 1;
  top: 6px;
  left: 3px;

  .logoPragmaticJackpot {
    width: 46px;
    height: auto;
  }

  .logoPragmaticJackpotLarge {
    width: 100px;

    @media (max-width: $sm) {
      max-width: 60px;
    }
  }

  .logoHabaneroJackpot {
    position: absolute;
    top: -3px;
    left: -1px;
    width: 46px;
    height: auto;
  }

  .logoHabaneroJackpotLarge {
    width: 100px;

    @media (max-width: $sm) {
      max-width: 60px;
    }
  }

  .logoMondayTag,
  .logoWeekendTag {
    width: 65px;
    height: auto;
  }

  .logoMondayTagLarge,
  .logoWeekendTagLarge {
    width: 100px;

    @media (max-width: $sm) {
      max-width: 65px;
    }
  }

  .logoThursdayTag {
    width: 65px;
    height: auto;
    top: -2px;
    left: -5px;
  }

  .logoThursdayTagLarge {
    position: absolute;
    width: 100px;

    @media (max-width: $sm) {
      max-width: 65px;
    }
  }

  .textTags {
    display: flex;
    align-items: center;
    font-size: 7px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%;
    text-transform: uppercase;

    span {
      padding: 2px;
    }
  }

  .textTagsSmall {
    font-size: 7px;
  }

  .textTagsDefault {
    font-size: 10px;

    @media (max-width: $sm) {
      font-size: 7px;
    }
  }

  .textTagsLarge {
    font-size: 14px;

    @media (max-width: $sm) {
      font-size: 7px;
    }
  }
}

.primaryColorTag {
  border: 2px solid #1d7aca;
  background: #2a9cff;
  box-shadow: 2px 2px 0 0 #0e406b;
}

.secondaryColorTag {
  border: 2px solid #157e14;
  background: #48ae47;
  box-shadow: 2px 2px 0px 0px #083607;
}

.exclusiveTag {
  border: 2px solid $brand-exclusive-border;
  background: $brand-exclusive;
  box-shadow: 2px 2px 0px 0px #460065;
}

.tournamentTag {
  border: 2px solid $brand-tournament-border;
  background: $brand-tournament;
  box-shadow: 2px 2px 0px 0px $brand-tournament-shadow;
}

.spinAndWinTag {
  border: 2px solid #157e14;
  background: #48ae47;
  box-shadow: 2px 2px 0px 0px #083607;
}
