@import 'src/styles/config';
.root {
  background: get(default-gradient);
  width: $mobileMenuHeight;
  height: $mobileMenuHeight;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  top: calc($mobileMenuHeight / -2);
}

.animatedBg {
  background-color: transparent;
  animation: pulse 2s infinite;
  width: 56px;
  height: 56px;
  position: absolute;
  border-radius: 50%;
  z-index: -1;
}

.disable {
  animation: none;
  background: #9e9e9e;
  cursor: disabled;
}

.text {
  color: white;
  text-transform: uppercase;
  font-weight: $font-weight-700;
  font-size: 14px;
}

.smallText {
  font-size: 11;
}

@keyframes pulse {
  0% {
    transform: scale(1);
    background-color: transparent;
  }

  70% {
    transform: scale(1.25);
    background-color: rgba(254, 19, 156, 0.4);
  }

  100% {
    transform: scale(1);
    background-color: transparent;
  }
}
