@import 'src/styles/config';
.root {
  &::after {
    left: 0;
  }

  &::before {
    left: 0;
  }
}

.root.SC {
  @include button-secondary-colors;
}

.root[role='link']:focus-visible {
  outline: 1px auto #3492f0;
}
