@import 'src/styles/config';
.root {
  position: absolute;
  background-color: $inputBg;
  box-shadow: 0 1px 3px rgb(0 0 0 / 20%), 0 2px -1px rgb(0 0 0 / 14%), 0 1px 5px rgb(0 0 0 / 12%);
  top: 35px;
  padding: 14px 16px 20px;
  width: 100%;
  border-top: 10px solid $inputBg;
  border-radius: 0 0 $border-radius-default $border-radius-default;
  z-index: 3;
}
