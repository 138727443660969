@import 'src/styles/config';
.searchBar {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: flex-start;
}

.search {
  width: 100%;
  max-width: 720px;
  position: relative;
  transition: width 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  background-color: #333;
  border-radius: $border-radius-8;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.55) inset;
  border: none;
}

.searchFullSize {
  width: 100%;
}

.input {
  padding-top: 8px;
  padding-bottom: 8px;
  height: 33px;
  font-weight: $font-weight-400;
}

.results {
  background-color: $base20;
  box-shadow: 0 1px 3px rgb(0 0 0 / 20%), 0 2px 2px rgb(0 0 0 / 14%), 0 1px 5px rgb(0 0 0 / 12%);
  position: absolute;
  top: 35px;
  padding: 0 16px;
  width: 100%;
  border-radius: 0 0 $radiusLG;
}

.noResults {
  h6 {
    font-size: 1rem;
    letter-spacing: 1px;
    font-weight: $font-weight-500;
  }
}

.button {
  margin-left: 8px;
  padding: 8px;
  color: get(text-secondary);
}
