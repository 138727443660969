@import 'src/styles/config';
.root {
  display: flex;
  height: 36px;
  position: relative;
  width: 100%;

  &:global(.headerMode) {
    align-items: center;
    height: unset;

    .labels {
      padding: 0;
    }
  }
}

.labels {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: 3px 0;
  text-align: left;
  color: $balanceBlockColor;
  gap: 4px 16px;
}

.reversedText {
  flex-flow: row wrap;
  justify-content: flex-start;
}

.label {
  display: block;
  font-size: 14px;
  font-weight: $font-weight-400;
  cursor: pointer;
  white-space: normal;
  user-select: none;
}

.labelMobile {
  @media (max-width: 430px) {
    white-space: inherit;
  }
}

.skeleton {
  height: 16px;

  @media screen and (max-width: $sm) {
    width: 56.58px;
    height: 28px;
  }
}
