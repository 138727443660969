@import 'src/styles/config';
$smooth-transition: all 0.3s ease-in-out;

.root {
  width: 100%;
  height: $sizing3Rem;
  position: relative;
  display: inline-flex;
  align-items: center;
  padding-left: $spacing1Rem;
  padding-right: $spacing1Rem;
  border-radius: $border-radius-default;
  transition: $smooth-transition;

  label {
    top: -6px;
    left: 10px;
    position: absolute;
    font-size: $fontMD;
    padding-left: 5px;
    padding-right: 5px;
    line-height: 1;
    color: $base60;
    background: transparent;
  }
}

.input {
  width: 100%;
  padding-top: 16px;
  padding-bottom: 16px;
  outline: none;
  font: inherit;
  border: none;
  color: $base100;
  background-color: $inputBg;
  font-size: 14px;
  transition: $smooth-transition;

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 60px $inputBg inset;
  }

  &:focus {
    outline: none;
    background: $inputBg;
  }

  &::placeholder {
    color: $base100;
  }

  &:not([value='']) {
    // background: $inputBg;
  }
}

.adornment {
  height: 0.01em;
  display: flex;
  max-height: 2em;
  align-items: center;
  white-space: nowrap;
}

.adornmentStart {
  margin-right: 8px;
}

.adornmentEnd {
  margin-left: 8px;
}

.clearButton {
  display: flex;
  align-items: center;
  padding: 0;
}

%icon {
  color: $base60;
  height: $sizing1Rem;
  width: $sizing1Rem;
  display: block;
}

.icon {
  @extend %icon;
}

.backspaceIcon {
  @extend %icon;

  color: $base100;
}

.loading {
  position: relative;

  &::before {
    content: '';
    position: absolute;
    right: 8px;
    width: 16px;
    height: 16px;
    border: 2px solid rgba(255, 255, 255, 0.5);
    border-top-color: #ffffff;
    border-left-color: #ffffff;
    border-radius: 50%;
    animation: button-loading-spinner 0.7s linear infinite;
  }
}

@keyframes button-loading-spinner {
  to {
    transform: rotate(360deg);
  }
}
