@import 'src/styles/config';
.arrowIcon {
  width: 100%;
  height: 100%;
  display: block;

  path {
    fill: $base100;
  }

  &.rotated {
    transform: rotate(180deg);
  }

  :hover {
    path {
      fill: $base95;
    }
  }

  :focus {
    path {
      fill: $base90;
    }
  }
}
