@import 'src/styles/config';
.dialogEmptyContent {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.dialogEmptyContentText {
  margin-top: $sizing20px;
  margin-bottom: $sizing30px;
  text-wrap: pretty;
}

.divider {
  height: 1px;
  width: 100%;
  background-color: #222629;
  margin: 0;
  border: none;
}

.dialogEmptyContentButton {
  margin-top: $sizing40px;
  width: 100%;
  min-height: $sizing40px;
}
