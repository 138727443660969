@import 'src/styles/config';
.root {
  h6 {
    color: $base100;
    font-size: 1rem;
    letter-spacing: 1px;
    font-weight: $font-weight-500;
  }

  & + .root {
    margin-top: 18px;
  }
}

.list {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 16px 8px;
  margin-top: 12px;
  padding-top: 0;
  padding-bottom: 0;
}

.seeMore {
  display: flex;
  justify-content: center;
  align-items: center;

  a {
    display: flex;
    align-items: center;
    font-weight: $font-weight-500;
    font-size: $fontSM;
    line-height: $lineMD;
  }
}

.icon {
  transform: rotate(-45deg);
  margin-left: 4px;
  height: $sizing0d75Rem;
  width: $sizing0d75Rem;
}

.gamesSectionItem {
  img {
    box-shadow: none !important;
  }
}
